<template>
	<div class="lithium-battery">
		<div class="header acea-row row-between-wrapper" ref="header">
			<div class="black" @click="returnChange()">
				<img src="@assets/images/icon_black.png" alt="" />
			</div>
			<p v-if="type=='1'">锂电芯打样</p>
		</div>
		<div class="lithium-content">
			<div class="lithium-tips">
				<p>打样须知：请填写以下项目，带*号为必填项; 提交后，我们会有专人与您联系，请保持电话畅通！</p>
			</div>
			<div class="lithium-item">
				<p><span>*</span>公司分类</p>
				<div class='content'>
					<CellGroup>
						<CellItem arrow type="label">
				            <select slot="right">
				                <option value="">选择品牌公司</option>
				                <option value="1">公司1</option>
				                <option value="2">公司2</option>
				                <option value="3">公司3</option>
				            </select>
				        </CellItem>
					</CellGroup>
				</div>
			</div>
			<div class="lithium-item">
				<p><span>*</span>电芯材料</p>
				<div class='content'>
					<CellGroup>
						<CellItem arrow type="label">
				            <select slot="right">
				                <option value="">选择电芯材料</option>
				                <option value="1">材料1</option>
				                <option value="2">材料2</option>
				                <option value="3">材料3</option>
				            </select>
				        </CellItem>
					</CellGroup>
				</div>
				
			</div>
			<div class="lithium-item">
				<p><span>*</span>电池封装</p>
				<div class='content'>
					<CellGroup>
						<CellItem arrow type="label">
				            <select slot="right">
				                <option value="">选择电池封装方式</option>
				                <option value="1">方式1</option>
				                <option value="2">方式2</option>
				                <option value="3">方式3</option>
				            </select>
				        </CellItem>
					</CellGroup>
				</div>
				
			</div>
			<div class="lithium-item">
				<p><span>*</span>放电倍率</p>
				<div class='content'>
					<CellGroup>
						<CellItem arrow type="label">
				            <select slot="right">
				                <option value="">选择电池放电倍率</option>
				                <option value="1">倍率1</option>
				                <option value="2">倍率2</option>
				                <option value="3">倍率3</option>
				            </select>
				        </CellItem>
					</CellGroup>
				</div>
			</div>
			<div class="lithium-item">
				<p>请输入倍率参数</p>
				<div class='content'>
					<div class="other-detail fir acea-row row-between">
						<span class="magn"><input type="text" /></span>
					</div>
				</div>
			</div>
		</div>
		<div class="detail-btn acea-row row-between">
			<div class="right bule sub-success" @click="save()">
				<p>下一步</p>
			</div>
		</div>	
	</div>
</template>
<script type="text/javascript">
import {CellGroup, CellItem} from 'vue-ydui/dist/lib.rem/cell';
export default {
	name:'lithiumCore',
	components: {
	    CellGroup,
	    CellItem
	},
	data(){
		return {
			type:'',
			value:''
		}
	},
	watch:{
		$$route() {
			this.type = this.$route.query.type;
		}
	},
	mounted(){
		this.type = this.$route.query.type;
	},
	methods:{
		//返回上一页
		returnChange() {
	      var that = this;
	      that.$router.go(-1);
	    },
	    save(){
	    	// console.log(this.value);
	    	this.$router.push({ path: "/lithium_battery?type=" + this.type });
	    }
	}
}
</script>
<style type="text/css">
.lithium-battery { padding-top:50px; }

.lithium-battery.router { background:#fff }
.lithium-battery .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
}
.lithium-battery .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
}
.lithium-battery .header .black img {
  width: 100%;
  height: 100%;
}
.lithium-battery .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
}
</style>

